// https://tools.ietf.org/html/rfc5322#section-3.4
const emailRegexp =
  /^[a-z0-9+'_-]+(?:\.[a-z0-9+'_-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

const phoneNumberRegexp = /^[()0-9 +-]*$/;

// 4-х байтовые символы не поддерживаются в utf8_unicode_ci, поэтому объявляем их невалидными.
const invalidSymbolsRegexp = /[\u{10000}-\u{10FFFF}]/u;

/**
 * @param {string} email
 * @returns {boolean}
 */
export function validateEmail(email) {
  return !email || emailRegexp.test(String(email.trim()).toLowerCase());
}

/**
 * @param {number|string} text
 * @returns {boolean}
 */
export function validatePhoneNumber(text) {
  return !text || phoneNumberRegexp.test(String(text.trim()));
}

export function validateText(text) {
  return !invalidSymbolsRegexp.test(String(text));
}

export function searchInvalidSymbols(text) {
  return String(text).match(invalidSymbolsRegexp);
}

/**
 * @param {Event} e
 * @returns {void}
 */
export function pasteNumberValidation(e) {
  let clipboardData = e.clipboardData || window.clipboardData;
  let pastedData = clipboardData.getData('Text').toLowerCase();

  if (pastedData.indexOf('e') > -1) {
    e.stopPropagation();
    e.preventDefault();
  }
}

/**
 * @param {Event} e
 * @returns {void}
 */
export function keypressNegativeNumberValidation(e) {
  const hasInvalidSymbols = ['+', 'e'].includes(e.key.toLowerCase());
  if (hasInvalidSymbols) {
    e.preventDefault();
  }
}

/**
 * @param {Event} e
 * @param {number} precision
 * @returns {void}
 */
export function restrictDecimal(e, precision) {
  if (precision === 0 && ['.', ','].includes(e.key.toLowerCase())) {
    e.preventDefault();
  }

  const decimalPart = `${e.target.value}${e.key}`.toString().split('.')[1] || '';
  if (decimalPart.length > precision) {
    e.preventDefault();
  }
}

/**
 * @param {Event} e
 * @param {number} precision
 * @returns {void}
 */
export function restrictDecimalOnPaste(e, precision) {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = clipboardData.getData('Text').toLowerCase();

  if (precision === 0 && ['.', ','].includes(pastedData)) {
    e.preventDefault();
  }

  let number = parseFloat(pastedData);

  if (isNaN(number)) {
    return;
  }

  number =
    precision === 0
      ? Math.trunc(number)
      : Math.trunc(number * Math.pow(10, precision)) / Math.pow(10, precision);

  e.preventDefault();

  const input = e.target;
  input.value = number;
  input.dispatchEvent(new Event('input'));
}

/**
 * @param {Event} e
 * @returns {void}
 */
export function keypressNumberValidation(e) {
  const hasInvalidSymbols = ['-', '+', 'e'].includes(e.key.toLowerCase());
  if (hasInvalidSymbols) {
    e.preventDefault();
  }
}

document.addEventListener('DOMContentLoaded', () => {
  let iconShowPassword =
    "%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.012 19.66c-7.859 0-11.755-6.851-11.917-7.143-0.126-0.227-0.126-0.503 0-0.73 0.162-0.291 4.058-7.142 11.917-7.142s11.755 6.851 11.917 7.143c0.126 0.227 0.126 0.503 0 0.73-0.162 0.291-4.058 7.142-11.917 7.142zM1.634 12.151c0.893 1.353 4.399 6.007 10.378 6.007 5.997 0 9.488-4.65 10.378-6.005-0.893-1.353-4.399-6.007-10.378-6.007-5.997 0-9.488 4.65-10.378 6.005zM12.012 16.657c-2.484 0-4.504-2.021-4.504-4.504s2.021-4.504 4.504-4.504c2.484 0 4.504 2.021 4.504 4.504s-2.021 4.504-4.504 4.504zM12.012 9.15c-1.656 0-3.003 1.347-3.003 3.003s1.347 3.003 3.003 3.003c1.656 0 3.003-1.347 3.003-3.003s-1.347-3.003-3.003-3.003z' fill='%231D2452'%3E%3C/path%3E%3C/svg%3E";
  let iconHidePassword =
    "%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.994 12.355l1.364-1.364c0.099 0.371 0.157 0.76 0.157 1.162 0 2.484-2.021 4.504-4.504 4.504-0.402 0-0.791-0.058-1.162-0.157l1.364-1.364c1.492-0.102 2.678-1.288 2.78-2.78zM23.929 11.788c-0.092-0.166-1.402-2.461-3.962-4.406l-1.093 1.093c1.867 1.373 3.049 2.973 3.515 3.679-0.89 1.355-4.38 6.005-10.378 6.005-0.899 0-1.736-0.115-2.523-0.299l-1.234 1.234c1.134 0.348 2.381 0.566 3.756 0.566 7.859 0 11.755-6.851 11.917-7.143 0.126-0.227 0.126-0.502 0-0.729zM20.801 4.425l-16.516 16.516c-0.147 0.147-0.339 0.22-0.531 0.22s-0.384-0.073-0.531-0.22c-0.293-0.293-0.293-0.768 0-1.061l2.101-2.101c-3.397-2.051-5.123-5.070-5.23-5.261-0.126-0.227-0.126-0.503 0-0.73 0.162-0.291 4.058-7.142 11.917-7.142 2.032 0 3.792 0.464 5.297 1.149l2.43-2.43c0.293-0.293 0.768-0.293 1.061 0s0.293 0.768 0 1.062zM6.444 16.659l1.901-1.901c-0.525-0.736-0.837-1.635-0.837-2.606 0-2.484 2.021-4.504 4.504-4.504 0.971 0 1.869 0.312 2.606 0.837l1.537-1.537c-1.214-0.486-2.594-0.801-4.143-0.801-5.997 0-9.488 4.65-10.378 6.005 0.565 0.856 2.189 3.024 4.81 4.508zM9.434 13.668l4.093-4.093c-0.447-0.264-0.961-0.425-1.516-0.425-1.656 0-3.003 1.347-3.003 3.003 0 0.555 0.162 1.069 0.425 1.516z' fill='%231D2452'%3E%3C/path%3E%3C/svg%3E";
  let showPassword = false;
  let showPasswordHover = false;
  let passwordFields = document.querySelectorAll('input[type=password]');

  function updateIconShowPassword(field) {
    field.setAttribute(
      'style',
      'background-image: url("data:image/svg+xml,' +
        (showPassword ? iconHidePassword : iconShowPassword) +
        '"); background-repeat: no-repeat; background-attachment: scroll; background-size: 20px 20px; background-position: 98% 50%; cursor: ' +
        (showPasswordHover ? 'pointer' : 'auto') +
        ';'
    );
  }

  for (let field of passwordFields) {
    updateIconShowPassword(field);
    field.addEventListener('click', e => {
      const elemWidth = e.target.clientWidth;
      const mouseX = e.pageX - e.target.getBoundingClientRect().left;
      if (mouseX > elemWidth * 0.98 - 16) {
        showPassword = !showPassword;
        updateIconShowPassword(e.target);
        e.target.type = showPassword ? 'text' : 'password';
      }
    });

    field.addEventListener('mousemove', e => {
      const elemWidth = e.target.clientWidth;
      const mouseX = e.pageX - e.target.getBoundingClientRect().left;
      showPasswordHover = mouseX > elemWidth * 0.98 - 16;
      updateIconShowPassword(e.target);
    });
  }
});
